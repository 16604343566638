import React from 'react'
import Layout from '../components/layout'

export default () => {
  
  return (
    <Layout>
      <h1>404 not found</h1>
      <article>
        <p>Whatever you're looking for, it's not here.</p>
      </article>
    </Layout>
  )
}
